import { cloneDeep } from 'lodash';
import { getFlagValue } from 'utils/getFlagValue';

import { getConfigIdFromDomain } from './whiteLabel';
import { CompanyName } from './whiteLabel/types';

export enum ConfigId {
  Endpoint,
  AHC,
  QETestOrg,
  QETestOrg2,
  OpsTest,
}

enum DomainId {
  AHC = 'accesshomeclosing',
  Endpoint = 'endpointclosing',
  QETestOrg = 'qetestorg',
  QETestOrg2 = 'qetestorg2',
  OpsTest = 'ops-test',
}

export interface EnvironmentConfig {
  ENVIRONMENT: string;
  WEBSITE_URL: string;
  GRAPHQL_URL: string;
  BFF_URL: string;
  LAUNCH_DARKLY_CLIENT_ID?: string;
  SEGMENT_WRITE_KEY?: string;
  DATADOG_RUM_APP_ID?: string;
  DATADOG_RUM_CLIENT_TOKEN?: string;
  DATADOG_RUM_SAMPLE_RATE?: number;
  DATADOG_RUM_PREMIUM_SAMPLE_RATE?: number;
}

export const AHC_DISPLAY_NAME = 'Access Home Closing';
export const ENDPOINT_DISPLAY_NAME = 'Endpoint';
export const QETESTORG_DISPLAY_NAME = 'QE Test Org';
export const QETESTORG2_DISPLAY_NAME = 'QE Test Org2';
export const OPS_TEST_DISPLAY_NAME = 'Ops Test';

type RuntimeConfigMap = {
  [key in ConfigId]: {
    companyName: CompanyName;
    config: AppConfig;
    domainId: DomainId;
  };
};

export type IdentityProviderConfig = {
  cognitoIdentityProvider: string;
  emailMatchRegex?: RegExp;
  emailDomain: string;
  nonSsoEmails?: string[];
  matchEmailSubstring?: string;
  partnerName?: string;
};

export type GoogleTagManagerConfig = {
  gtmId: string;
  auth: string;
  preview: string;
};

export const RUNTIME_CONFIG_MAP: RuntimeConfigMap = {
  [ConfigId.Endpoint]: {
    companyName: 'Endpoint',
    config: window.CONFIG[ConfigId.Endpoint] as AppConfig,
    domainId: DomainId.Endpoint,
  },
  [ConfigId.AHC]: {
    companyName: 'AHC',
    config: window.CONFIG[ConfigId.AHC] as AppConfig,
    domainId: DomainId.AHC,
  },
  [ConfigId.QETestOrg]: {
    companyName: 'QETestOrg',
    config: window.CONFIG[ConfigId.QETestOrg] as AppConfig,
    domainId: DomainId.QETestOrg,
  },
  [ConfigId.QETestOrg2]: {
    companyName: 'QETestOrg2',
    config: window.CONFIG[ConfigId.QETestOrg2] as AppConfig,
    domainId: DomainId.QETestOrg2,
  },
  [ConfigId.OpsTest]: {
    companyName: 'Ops',
    config: window.CONFIG[ConfigId.OpsTest] as AppConfig,
    domainId: DomainId.OpsTest,
  },
};

export const HOST_NAME = window.location.hostname;

export const CONFIG_ID = getConfigIdFromDomain(HOST_NAME);
// Clone config when application bootstraps to prevent malicious changes to config at runtime
const CONFIG = cloneDeep(RUNTIME_CONFIG_MAP[CONFIG_ID].config);

const COMPANY_SHORTNAME = cloneDeep(RUNTIME_CONFIG_MAP[CONFIG_ID].companyName).toString();

export function getConfig(): AppConfig {
  return CONFIG;
}

export const getEnvironmentConfig = async (): Promise<EnvironmentConfig> => {
  let isAppConfigsFromOrgService = false;

  try {
    isAppConfigsFromOrgService = await getFlagValue('isAppConfigsFromOrgService', false, {
      kind: 'boolean',
      key: 'isAppConfigsFromOrgService',
    });
  } catch (error) {
    console.error('Error getting flag value for isAppConfigsFromOrgService:', error);
  }

  if (isAppConfigsFromOrgService) {
    return {
      ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT ?? '',
      WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL ?? '',
      GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL ?? '',
      BFF_URL: process.env.REACT_APP_BFF_URL ?? '',
      LAUNCH_DARKLY_CLIENT_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
      SEGMENT_WRITE_KEY: process.env.REACT_APP_SEGMENT_WRITE_KEY,
      DATADOG_RUM_APP_ID: process.env.REACT_APP_DATADOG_RUM_APP_ID,
      DATADOG_RUM_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
      DATADOG_RUM_SAMPLE_RATE: Number(process.env.REACT_APP_DATADOG_RUM_SAMPLE_RATE),
      DATADOG_RUM_PREMIUM_SAMPLE_RATE: Number(process.env.REACT_APP_DATADOG_RUM_PREMIUM_SAMPLE_RATE),
    };
  }

  return {
    ENVIRONMENT: CONFIG.ENVIRONMENT,
    WEBSITE_URL: CONFIG.WEBSITE_URL,
    GRAPHQL_URL: CONFIG.GRAPHQL_URL,
    BFF_URL: CONFIG.BFF_URL,
    LAUNCH_DARKLY_CLIENT_ID: CONFIG.LAUNCH_DARKLY_CLIENT_ID,
    SEGMENT_WRITE_KEY: CONFIG.SEGMENT_WRITE_KEY,
    DATADOG_RUM_APP_ID: CONFIG.DATADOG_RUM_APP_ID,
    DATADOG_RUM_CLIENT_TOKEN: CONFIG.DATADOG_RUM_CLIENT_TOKEN,
    DATADOG_RUM_SAMPLE_RATE: Number(CONFIG.DATADOG_RUM_SAMPLE_RATE),
    DATADOG_RUM_PREMIUM_SAMPLE_RATE: Number(CONFIG.DATADOG_RUM_PREMIUM_SAMPLE_RATE),
  };
};

const getCompanyBrandName = (configId: ConfigId): string => {
  return {
    [ConfigId.AHC]: AHC_DISPLAY_NAME,
    [ConfigId.Endpoint]: ENDPOINT_DISPLAY_NAME,
    [ConfigId.QETestOrg]: QETESTORG_DISPLAY_NAME,
    [ConfigId.QETestOrg2]: QETESTORG2_DISPLAY_NAME,
    [ConfigId.OpsTest]: OPS_TEST_DISPLAY_NAME,
  }[configId];
};

export function getCompanyDefaults() {
  const COMPANY_BRANDNAME = getCompanyBrandName(CONFIG_ID);

  return {
    COMPANY_SHORTNAME,
    COMPANY_BRANDNAME,
  };
}
